import dayjs from 'dayjs'

const transformDateToHumanReadablePretty = date => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('DD.MM.YYYY')
const transformDatetimeToHumanReadablePretty = date => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('DD.MM.YYYY HH:mm')
const transformTimeToHumanReadablePretty = date => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('HH:mm')

export {
  transformDateToHumanReadablePretty,
  transformDatetimeToHumanReadablePretty,
  transformTimeToHumanReadablePretty,
}
