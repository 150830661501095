import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import flatpickr from 'flatpickr'
import { $themeBreakpoints } from '@themeConfig'
import store from '@/store/store'

export default (props, { emit }) => {
  const dateRange = props.value
  const dateRangePickerRef = ref()
  const dateRangeDropdownRef = ref()
  const me = store.getters['auth/getUser']
  const organizationCreatedAt = computed(() => dayjs(me.organization.createdAt))
  let flatpickrObject = null

  const resolveMonthsNumber = () => {
    if ($themeBreakpoints.lg > store.state.app.windowWidth) {
      return 1
    }

    return 2
  }

  const configDateTimePicker = {
    locale: PolishLocale,
    disableMobile: true,
    dateFormat: 'd.m.Y',
    inline: true,
    showMonths: resolveMonthsNumber(),
    mode: 'range',
    static: true,
    maxRange: 90,
    onChange: ((dates, dateStr, instance) => {
      if (dates.length === 1) {
        const ninetyDaysLater = new Date(
          dates[0].getTime() + 86400000 * 366,
        )
        instance.set('maxDate', ninetyDaysLater.toUTCString())
      }

      if (dates.length === 2) {
        dateRange.value = dates
        emit('input', dateRange.value)
      }
    }),
  }

  const applyDateRangePicker = (fromDate, range, rangeUnits) => {
    const from = fromDate.startOf('day')
    const to = from.add(range, rangeUnits).subtract(1, 'second')

    flatpickrObject.setDate([from.toDate(), to.toDate()], true)
  }

  const onDateRangePickerShown = () => {
    flatpickrObject = flatpickr(dateRangePickerRef.value, configDateTimePicker)
    flatpickrObject.setDate(props.value)
  }

  return {
    // variables
    dateRange,
    dateRangePickerRef,
    dateRangeDropdownRef,
    me,
    organizationCreatedAt,

    // methods
    dayjs,
    configDateTimePicker,
    onDateRangePickerShown,
    applyDateRangePicker,
  }
}
